import { formatThousands, formatPrice } from 'utils/formatters';

export const POWER_LIST_KW = [
	{ value: 25, label: '25 kW' },
	{ value: 37, label: '37 kW' },
	{ value: 44, label: '44 kW' },
	{ value: 55, label: '55 kW' },
	{ value: 66, label: '66 kW' },
	{ value: 74, label: '74 kW' },
	{ value: 87, label: '87 kW' },
	{ value: 96, label: '96 kW' },
	{ value: 110, label: '110 kW' },
	{ value: 147, label: '147 kW' },
	{ value: 185, label: '185 kW' },
	{ value: 223, label: '223 kW' },
	{ value: 263, label: '263 kW' },
	{ value: 296, label: '296 kW' },
	{ value: 334, label: '334 kW' },
];


export const MILEAGE_LIST = [
	{ value: 2500, label: formatThousands(2500) },
	{ value: 5000, label: formatThousands(5000) },
	{ value: 10000, label: formatThousands(10000) },
	{ value: 15000, label: formatThousands(15000) },
	{ value: 20000, label: formatThousands(20000) },
	{ value: 30000, label: formatThousands(30000) },
	{ value: 40000, label: formatThousands(40000) },
	{ value: 50000, label: formatThousands(50000) },
	{ value: 60000, label: formatThousands(60000) },
	{ value: 70000, label: formatThousands(70000) },
	{ value: 80000, label: formatThousands(80000) },
	{ value: 90000, label: formatThousands(90000) },
	{ value: 100000, label: formatThousands(100000) },
	{ value: 125000, label: formatThousands(125000) },
	{ value: 150000, label: formatThousands(150000) },
	{ value: 175000, label: formatThousands(175000) },
	{ value: 200000, label: formatThousands(200000) },
];

export const PACKAGE_VALUES_LIST = [
	{ value: 300000, label: formatPrice(300000, 'CZK') },
	{ value: 500000, label: formatPrice(500000, 'CZK') },
	{ value: 700000, label: formatPrice(700000, 'CZK') },
	{ value: 1000000, label: formatPrice(1000000, 'CZK') },
	{ value: 1500000, label: formatPrice(1500000, 'CZK') },
	{ value: 2000000, label: formatPrice(2000000, 'CZK') },
	{ value: 3000000, label: formatPrice(3000000, 'CZK') },
	{ value: 4000000, label: formatPrice(4000000, 'CZK') },
	{ value: 5000000, label: formatPrice(5000000, 'CZK') },
	{ value: 7000000, label: formatPrice(7000000, 'CZK') },
	{ value: 10000000, label: formatPrice(10000000, 'CZK') },
	{ value: 15000000, label: formatPrice(15000000, 'CZK') },
	{ value: 20000000, label: formatPrice(20000000, 'CZK') },
];

export const PACKAGE_MARGIN_PERCENT_LIST = [
	{ value: 5, label: '5 %' },
	{ value: 10, label: '10 %' },
	{ value: 15, label: '15 %' },
	{ value: 20, label: '20 %' },
	{ value: 25, label: '25 %' },
	{ value: 30, label: '30 %' },
	{ value: 40, label: '40 %' },
	{ value: 50, label: '50 %' },
];
