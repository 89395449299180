import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { selectMakeOptionsFromReferenceData } from 'selectors/referenceData';
import { Button, Select, Form, Input } from 'antd';
import { Trans, t } from '@lingui/macro';
import { makeModelsFromDataWithGroup } from 'utils/activeFilters';
import useFetch from 'hooks/useFetch';
import styled from 'styled-components/macro';
import { OptionSelect } from 'components/OptionSelect';
import filterOption from './utils/filterOption';
import { FormItem, FormItemsWrap } from 'pages/Dashboard/components/FilterDesignComponents';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { i18n } from '@lingui/core';

type ModelOptionType = {
	value: number | string;
	label: string;
	nested?: boolean;
};

type Values = {
	[key: string]: any;
};

type MakeAndModelItemProps = {
	form: WrappedFormUtils<Values>;
	name: string;
	onAddClick: () => void;
	onRemoveClick: () => void;
	showAdd?: boolean;
	showRemove?: boolean;
	validator?: () => void;
};

const NestedItem = styled.div`
	.ant-select-dropdown-menu & {
		padding-left: 20px;
	}
`;

export const MakeAndModelItem: FunctionComponent<MakeAndModelItemProps> = ({
	form,
	name,
	onAddClick,
	onRemoveClick,
	showAdd,
	showRemove,
	validator,
}) => {
	const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

	const makeName = `make-${name}`;
	const modelFamilyName = `model-${makeName}`;
	const modelPercentageRatio = `model-percentage-${name}`;

	const selectedMake = getFieldValue(makeName);
	const { data: modelData, isLoading } = useFetch(`/api/catalog/model?make=${selectedMake}`, {
		lazy: !selectedMake,
	});

	const allMakeOptions = useSelector(selectMakeOptionsFromReferenceData);

	if (!allMakeOptions) return null;

	return (
		<FormItemsWrap>
			<FormItem>
				{getFieldDecorator(makeName)(
					<OptionSelect
						options={allMakeOptions}
						placeholder={<Trans>FILTERS_MAKE_PLACEHOLDER</Trans>}
						onChange={() => {
							// reset model when make changes
							setFieldsValue({
								[modelFamilyName]: [],
							});
						}}
						allowClear
					/>,
				)}
			</FormItem>
			<FormItem>
				{getFieldDecorator(modelFamilyName)(
					<Select
						showSearch
						placeholder={<Trans>FILTERS_MODEL_PLACEHOLDER</Trans>}
						loading={isLoading}
						allowClear
						filterOption={filterOption}
						mode="multiple"
					>
						{modelData &&
							makeModelsFromDataWithGroup(modelData.model).map(
								(option: ModelOptionType) => (
									<Select.Option key={option.value} value={option.value}>
										{option.nested ? (
											<NestedItem>{option.label}</NestedItem>
										) : (
											option.label
										)}
									</Select.Option>
								),
							)}
					</Select>,
				)}
			</FormItem>
			<FormItem>
				<Form.Item>
					{getFieldDecorator(modelPercentageRatio)(
						<Input
							placeholder={i18n._(t`FILTERS_RATIO_PLACEHOLDER`)}
							min={1}
							max={99}
							type="number"
							allowClear
						/>,
					)}
				</Form.Item>
			</FormItem>
			{showAdd ? <Button onClick={onAddClick}>+</Button> : null}
			{showRemove ? <Button onClick={onRemoveClick}>-</Button> : null}
		</FormItemsWrap>
	);
};
