import Layout from 'components/PrivateLayout';
import React, { FunctionComponent, useState, useMemo, useCallback, useEffect } from 'react';
import { Button, Form, notification } from 'antd';
import { Trans, t } from '@lingui/macro';
import { RouteComponentProps } from 'react-router-dom';
import useFetch from 'hooks/useFetch';
import apiFetch from 'utils/apiFetch';
import { useLingui } from '@lingui/react';
import { CancelConfirmModal } from './CancelConfirmModal';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { StyledLink, StyledIcon, Container, MenuLabel, MenuForm, MenuFooter } from './styled';
import { CarInfoFields, ReviewAboutFields, RatingFields, BasicInfoFields } from './ReviewsField';
import { FormValues } from './types';
import { getTranslationsData } from './helpers';
import { UploadCar } from './ReviewsField/UploadCar';
import { handleUploadCarReviewImage } from './ReviewsField/handleUploadCarReviewImage';
import { RcFile } from 'antd/lib/upload/interface';
import { ReviewPositionFields } from 'pages/ReviewDetail/ReviewsField/ReviewPositionFields';

type Props = { form: WrappedFormUtils } & RouteComponentProps<{ id?: string }>;

const ReviewDetail: FunctionComponent<Props> = ({
	form: { validateFields, getFieldDecorator, setFieldsValue, getFieldValue },
	history,
	match,
}: Props) => {
	const editing = match.params.id != null;
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
	const { i18n } = useLingui();
	const [fileList, setFileList] = useState<Array<RcFile>>([]);
	const [isFileLoading, setFileLoading] = useState(false);

	const { data, isLoading, fetchData, error } = useFetch('/api/carvago-admin/reviews/:id', {
		lazy: true,
	});

	useEffect(() => {
		if (error) {
			notification.error({
				message: <Trans id="REVIEWS_LOAD_ERROR" />,
			});
			throw error;
		}
	}, [error]);

	useEffect(() => {
		setFieldsValue({
			make: [data?.make],
		});
	}, [data?.make, setFieldsValue]);

	const defaultValues = useMemo(
		(): FormValues => ({
			answer: data?.answer ?? null,
			customerName: data?.customer_name,
			review: data?.review,
			visibility: data?.is_visible ?? false,
			isPrioritized: data?.is_prioritized ?? false,
			country: data?.country.id,
			language: data?.language.id,
			recommendsCarvago: data?.recommendsCarvago ?? false,
			make: data?.make,
			modelFamily: data?.model,
			verifiedBuyer: data?.verified_buyer ?? true,
			reviewSource: data?.review_source ?? null,
			reviewSourceLink: data?.review_url ?? null,
			yearOfManufacture: data?.year_of_manufacture,
			rating: data?.rating,
			homepage: data?.homepage ?? false,
			translations: data?.review_translation,
			photo: data?.photo ?? null,
			orderingNumber: data?.ordering_number ?? null,
		}),
		[data],
	);

	useEffect((): void => {
		if (editing) {
			fetchData({ id: match.params.id }).catch((error: Error): void => {
				notification.error({
					message: <Trans id="REVIEWS_LOAD_ERROR" />,
				});
				throw error;
			});
		}
	}, [editing, fetchData, match.params.id]);

	useEffect(() => {
		const initialPhoto = defaultValues?.photo
			? [
				{
					uid: defaultValues?.photo.id,
					size: defaultValues?.photo.file_size,
					name: defaultValues?.photo.file_name,
					type: defaultValues?.photo.file_mime_type,
					url: `${process.env.REACT_APP_IMAGE_URL}${defaultValues?.photo.storage_service_id}`,
				},
			  ]
			: [];

		// @ts-ignore
		setFileList(initialPhoto);
	}, [defaultValues]);

	const handleCancelButtonClick = useCallback((): void => {
		setIsCancelModalOpen(true);
	}, [setIsCancelModalOpen]);

	const handleSubmit = useCallback(
		(event: React.FormEvent): void => {
			event.preventDefault();
			validateFields((errors: Error, values: FormValues): void => {
				if (errors != null) {
					return;
				}

				const modifiedTranslationObject = getTranslationsData(values);

				const editedAnswer = values.answer === '' ? null : values.answer;

				const body = {
					customer_name: values.customerName,
					review: values.review,
					answer: editing ? editedAnswer : values.answer ?? null,
					is_visible: values.visibility,
					is_prioritized: values.isPrioritized,
					ordering_number: values.orderingNumber ?? null,
					country: { id: values.country },
					language: { id: values.language },
					recommends_carvago: editing
						? values.recommendsCarvago
						: values.recommendsCarvago ?? false,
					make: values.make,
					model:  values.modelFamily ,
					verified_buyer: values.verifiedBuyer,
					review_source: values.reviewSource ?? null,
					review_url: values.reviewSourceLink === '' ? null : values.reviewSourceLink,
					year_of_manufacture: values.yearOfManufacture ?? undefined,
					rating: values.rating,
					homepage: editing ? values.homepage : values.homepage ?? false,
					...(editing && { review_translation: modifiedTranslationObject.flat() }),
				};

				apiFetch(`/api/carvago-admin/reviews${editing ? `/${match.params.id}` : ''}`, {
					method: editing ? 'PUT' : 'POST',
					body: JSON.stringify(body),
					headers: {
						'Content-Type': 'application/json',
					},
				})
					.then((response) => response.json())
					.then(async (response) => {
						await handleUploadCarReviewImage(
							fileList,
							setFileLoading,
							response,
							history,
						);
					})
					.catch((error: Error): void => {
						notification.error({
							message: i18n._(t`REVIEWS_SAVE_ERROR`),
						});
						throw error;
					})
					.finally(() => {
						setFileLoading(false);
					});
			});
		},
		[validateFields, editing, match.params.id, fileList, history, i18n],
	);

	return (
		<Layout
			noSider
			header={
				<StyledLink to="/reviews">
					<StyledIcon type="arrow-left" />
					<Trans id="REVIEWS_BACK_TO_LIST" />
				</StyledLink>
			}
		>
			<Container>
				<MenuLabel>
					<Trans id="REVIEWS_EDIT_TITLE" />
				</MenuLabel>
				<Form onSubmit={handleSubmit}>
					<MenuForm>
						<UploadCar
							apiLoading={isFileLoading}
							data={data}
							fileList={fileList}
							setFileList={setFileList}
						/>
						<BasicInfoFields
							editing={editing}
							setFieldsValue={setFieldsValue}
							getFieldDecorator={getFieldDecorator}
							isLoading={isLoading}
							defaultValues={defaultValues}
						/>
						<RatingFields
							getFieldDecorator={getFieldDecorator}
							isLoading={isLoading}
							defaultValues={defaultValues}
						/>
						<ReviewAboutFields
							getFieldDecorator={getFieldDecorator}
							isLoading={isLoading}
							defaultValues={defaultValues}
						/>
						<CarInfoFields
							setFieldsValue={setFieldsValue}
							getFieldDecorator={getFieldDecorator}
							isLoading={isLoading}
							defaultValues={defaultValues}
							getFieldValue={getFieldValue}
						/>
						<ReviewPositionFields
							isLoading={isLoading}
							getFieldDecorator={getFieldDecorator}
							defaultValues={defaultValues}
						/>
						<MenuFooter>
							<Button type="link" onClick={handleCancelButtonClick}>
								<Trans id="CANCEL" />
							</Button>
							<div>
								<Button type="primary" size="large" htmlType="submit">
									<Trans id="SAVE" />
								</Button>
							</div>
						</MenuFooter>
					</MenuForm>
				</Form>
			</Container>
			<CancelConfirmModal
				isModalOpen={isCancelModalOpen}
				onCancel={(): void => setIsCancelModalOpen(false)}
			/>
		</Layout>
	);
};

export default Form.create({ name: 'review_edit' })(ReviewDetail);
