import { FiltersType } from 'types/filters';
import {  isArray } from 'lodash';
import { FeedType } from '../types/feed';
import { ModelFamilyType } from '../types/modelFamily';
import { FilterReviewsType } from '../pages/Reviews/types';

export const GROUP_PREFIX = `group_`;

type ActiveFiltersType = FiltersType & FeedType & FilterReviewsType;

export const paramsFromActiveFilters = (object: ActiveFiltersType) => {
	const urlParams: string[] = [];

	Object.keys(object).forEach((key) => {
		if (isArray(object[key as keyof ActiveFiltersType])) {
			const arr = object[key as keyof ActiveFiltersType] as Array<number | string>;
			if (arr.length > 0) {
				urlParams.push(`${encodeURIComponent(key)}[]=${arr.join(';')}`);
			}
		} else {
			urlParams.push(
				`${encodeURIComponent(key)}=${encodeURIComponent(
					object[key as keyof ActiveFiltersType] as string | number | boolean,
				)}`,
			);
		}
	});

	return urlParams.join('&');
};

export const makeModelsFromDataWithGroup = (data: any[]) => {
	if (!data) {
		return [];
	}

	const groups:Record<string | number, {name: string, models: [{label: string, value: number|string, nested: boolean}]}> = {};
	const modelsWithoutGroup = [];
	for(const item of data) {
		if (item.group){
			const { label: name, group: const_key} = item.group;

			if(!groups[const_key]) {
				groups[const_key] = {
					name,
					models: [{
						value: item.model,
						label: item.label,
						nested: true,
					}],
				};
			} else {
				groups[const_key].models.push({
					value: item.model,
					label: item.label,
					nested: true,
				});
			}
		} else {
			modelsWithoutGroup.push({
				value: item.model,
				label: item.label,
				
			});
		}
	}

	const models = [];
	for(const groupId in groups){
		models.push({ value: `${groupId}`, label: groups[groupId].name});
		models.push(...groups[groupId].models);
	}
	models.push(...modelsWithoutGroup);

	return models;
};
