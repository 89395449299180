import React, { useEffect, FunctionComponent, useState, useRef } from 'react';
import { get, isEmpty } from 'lodash';
import { Col } from 'antd';
import { FiltersType } from 'types/filters';
import { MakeAndModelItem } from './MakeAndModelItem';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { useSelector } from 'react-redux';
import { selectMakeFromReferenceData } from 'selectors/referenceData';

type Values = {
	[key: string]: any;
};

type MakeAndModelProps = {
	initialValues: FiltersType;
	form: WrappedFormUtils<Values>;
};

export type MakeAndModelFamily = {
	makeId: string;
	modelId: string;
	const_key: string;
};

type MakeAndModelFamilyCollection = Record<string, string[]>;

const collectModelFamiliesPerMake = (
	responses: Array<MakeAndModelFamily>,
): MakeAndModelFamilyCollection =>
	responses.reduce((result, modelFamily) => {
		const makeId = modelFamily.const_key;

		if (!result[makeId]) {
			result[makeId] = [modelFamily.modelId as string];
		} else {
			result[makeId].push(modelFamily.modelId as string);
		}

		return result;
	}, {} as MakeAndModelFamilyCollection);

export const MakeAndModel: FunctionComponent<MakeAndModelProps> = ({
	form,
	initialValues = {},
}) => {
	const { setFieldsValue } = form;
	const lastKey = useRef(0);
	const [makersCount, setMakersCount] = useState([{ name: `id-${lastKey.current}` }]);
	const makeAll = useSelector(selectMakeFromReferenceData);

	const getMakeForModelFamily = (modelId: string) => {
		const makeConstKey = modelId.split('-')[0];
		const currentMake = makeAll.find((make: any) => make.const_key === makeConstKey);
		return {
			makeId: currentMake.id,
			modelId,
			const_key: currentMake.const_key,
		} as MakeAndModelFamily;
	};

	useEffect(() => {
		const modelFamilies = get(initialValues, 'model', []) as string[];
		const modelFamilyGroups = get(initialValues, 'model-group', []) as string[];
		const modelPercentage = get(initialValues, 'model-percentage', []);

		if (
			makeAll &&
			(!isEmpty(modelFamilyGroups) || !isEmpty(modelFamilies)) &&
			!initialValues.make
		) {
			const models = [...modelFamilyGroups, ...modelFamilies].map((id) =>
				getMakeForModelFamily(id),
			);

			const modelsPerFamily = collectModelFamiliesPerMake(models);
			const makeIds = Object.keys(modelsPerFamily);
			const makeModelFields = makeIds.map((id, index) => ({ name: `id-${index}` }));
			lastKey.current = makeIds.length - 1;

			const makeModelValues = makeIds.reduce(
				(result, const_key, index) => ({
					...result,
					[`make-id-${index}`]: [const_key],
					[`model-make-id-${index}`]: modelsPerFamily[const_key],
					[`model-percentage-id-${index}`]: modelPercentage[index],
				}),
				{},
			);
			setMakersCount(makeModelFields);
			setTimeout(() => {
				setFieldsValue(makeModelValues);
			}, 100);
		}
	}, [makeAll]);

	return (
		<Col span={8}>
			{makersCount.map((data, index) => {
				return (
					<MakeAndModelItem
						key={data.name}
						name={data.name}
						form={form}
						showRemove={makersCount.length > 1}
						showAdd={index === makersCount.length - 1}
						onAddClick={() =>
							setMakersCount((makers) => [
								...makers,
								{ name: `id-${++lastKey.current}` },
							])
						}
						onRemoveClick={() =>
							setMakersCount((makers) =>
								makers.filter(({ name }) => !name.startsWith(data.name)),
							)
						}
					/>
				);
			})}
		</Col>
	);
};
