import { FEED_SUPPORTED_LANGUAGES, REVIEWS_SUPPORTED_COUNTRIES } from 'constants/index';

import { createSelector } from 'reselect';
import { iRootState } from 'store';
import { get, isArray } from 'lodash';
import { Country } from 'types/enum';
import { OptionType } from 'types/selectOptions';
import { LanguageType } from '../types/language';

type ItemType = {
	id: string;
	name: string;
	[key: string]: string;
};

export const makeOptionsFromData = (
	data: ItemType[] = [],
	additionalFields: string[] = [],
): OptionType[] =>
	data
		? data.map((item) => {
			if (isArray(item)) {
				const group = get(item[0], 'model_family_group', {});
				return {
					label: group.name,
					options: makeOptionsFromData(item),
					nested: true,
				};
			}
			return Object.assign(
				{},
				{
					value: item.const_key ?? item.id,
					label: item.name,
				},
				...additionalFields.map((key) => ({
					[key]: item[key],
				})),
			);
		})
		: [];

export const makeOptionsFromDataByID = (
	data: ItemType[] = [],
	additionalFields: string[] = [],
): OptionType[] =>
	data
		? data.map((item) => {
			return Object.assign(
				{},
				{
					value: item.id,
					label: item.name,
				},
				...additionalFields.map((key) => ({
					[key]: item[key],
				})),
			);
		})
		: [];

const makeModelsFromData = (data: any = {}) => {
	const newData = { ...data };

	Object.keys(data).forEach((key) => {
		newData[key] = makeOptionsFromData(data[key]);
	});

	return newData;
};

const selectReferenceDataState = (state: iRootState) => {
	return state.referenceData;
};

export const selectMakeFromReferenceData = createSelector(selectReferenceDataState, (referenceData) => referenceData.make);
export const selectMakeOptionsFromReferenceData = createSelector(selectMakeFromReferenceData, (allMakes) => allMakes && allMakes.length > 0 ? allMakes.map((make: any) => ({
	value: make.const_key,
	label: make.name,
})) : null);

export const selectReferenceData = createSelector(selectReferenceDataState, (referenceData) => ({
	airConditioningOptions: makeOptionsFromData(get(referenceData, 'air_conditioning')),
	audioOptions: makeOptionsFromData(get(referenceData, 'audio')),
	auxiliaryHeatingOptions: makeOptionsFromData(get(referenceData, 'auxiliary_heating')),
	carStyleOptions: makeOptionsFromData(get(referenceData, 'car_style')),
	ceilingMaterialOptions: makeOptionsFromData(get(referenceData, 'ceiling_material')),
	colorOptions: makeOptionsFromData(get(referenceData, 'color'), ['const_key']),
	colorShadeOptions: makeOptionsFromData(get(referenceData, 'color_shade'), ['const_key']),
	colorSpecificationOptions: makeOptionsFromData(get(referenceData, 'color_specification'), [
		'const_key',
	]),
	conditionOptions: makeOptionsFromData(get(referenceData, 'condition')),
	countryOptions: makeOptionsFromDataByID(get(referenceData, 'country'), ['const_key', 'iso_code', 'currency']),
	allowedCountryOptions: makeOptionsFromDataByID(get(referenceData, 'country'), ['const_key', 'currency']).filter(
		(currency) => {
			return (
				get(currency, 'const_key') === 'COUNTRY_GERMANY' ||
				get(currency, 'const_key') === 'COUNTRY_CZECH_REPUBLIC' ||
				get(currency, 'const_key') === 'COUNTRY_SLOVAKIA' ||
				get(currency, 'const_key') === 'COUNTRY_HUNGARY' ||
				get(currency, 'const_key') === 'COUNTRY_POLAND'
			);
		},
	),
	supportedCountryOptions: makeOptionsFromDataByID(get(referenceData, 'country'), ['const_key']).filter(
		(country) => {
			return (
				get(country, 'const_key') === 'COUNTRY_GERMANY' ||
				get(country, 'const_key') === 'COUNTRY_CZECH_REPUBLIC' ||
				get(country, 'const_key') === 'COUNTRY_SLOVAKIA' ||
				get(country, 'const_key') === 'COUNTRY_AUSTRIA' ||
				get(country, 'const_key') === 'COUNTRY_FRANCE' ||
				get(country, 'const_key') === 'COUNTRY_SPAIN' ||
				get(country, 'const_key') === 'COUNTRY_ITALY' ||
				get(country, 'const_key') === 'COUNTRY_POLAND'
			);
		},
	),
	cruiseControlOptions: makeOptionsFromData(get(referenceData, 'cruise_control')),
	// TODO remove filter currencies after BE prepared
	currencyOptions: makeOptionsFromData(get(referenceData, 'currency'), ['const_key']).filter(
		(currency) => {
			return (
				get(currency, 'const_key') === 'CURRENCY_EUR' ||
				get(currency, 'const_key') === 'CURRENCY_CZK' ||
				get(currency, 'const_key') === 'CURRENCY_PLN'
			);
		},
	),
	customerType: makeOptionsFromData(get(referenceData, 'customer_type')),
	displayOptions: makeOptionsFromData(get(referenceData, 'display')),
	doorCountOptions: makeOptionsFromData(get(referenceData, 'door_count')),
	driveOptions: makeOptionsFromData(get(referenceData, 'drive')),
	documentStatus: makeOptionsFromData(get(referenceData, 'document_status')),
	documentType: makeOptionsFromData(get(referenceData, 'document_type')),
	emissionClassOptions: makeOptionsFromData(get(referenceData, 'emission_class')),
	featureCategoryOptions: makeOptionsFromData(get(referenceData, 'feature_category')),
	featureSubcategoryOptions: makeOptionsFromData(get(referenceData, 'feature_subcategory')),
	foldingRoofOptions: makeOptionsFromData(get(referenceData, 'folding_roof'), ['const_key']),
	fuelTypeOptions: makeOptionsFromData(get(referenceData, 'fuel_type')),
	handsFreeOptions: makeOptionsFromData(get(referenceData, 'hands_free')),
	headlightsTypeOptions: makeOptionsFromData(get(referenceData, 'headlights_type'), ['const_key']),
	interiorColorOptions: makeOptionsFromData(get(referenceData, 'interior_color')),
	interiorMaterialOptions: makeOptionsFromData(get(referenceData, 'interior_material')),
	makeOptions: makeOptionsFromData(get(referenceData, 'make')),
	modelOptions: makeModelsFromData(get(referenceData, 'models')),
	parkingCameraOptions: makeOptionsFromData(get(referenceData, 'parking_camera')),
	parkingSensorsOptions: makeOptionsFromData(get(referenceData, 'parking_sensors')),
	priceRatingOptions: makeOptionsFromData(get(referenceData, 'price_rating')),
	priceTypeOptions: makeOptionsFromData(get(referenceData, 'price_type')),
	rearSpoilerOptions: makeOptionsFromData(get(referenceData, 'rear_spoiler')),
	sellerTypeOptions: makeOptionsFromData(get(referenceData, 'seller_type'), ['const_key']),
	slidingDoorsOptions: makeOptionsFromData(get(referenceData, 'sliding_doors')),
	spareTyreOptions: makeOptionsFromData(get(referenceData, 'spare_tyre')),
	sunroofOptions: makeOptionsFromData(get(referenceData, 'sunroof')),
	tailgateOpeningOptions: makeOptionsFromData(get(referenceData, 'tailgate_opening')),
	trailerCouplingOptions: makeOptionsFromData(get(referenceData, 'trailer_coupling')),
	transmissionOptions: makeOptionsFromData(get(referenceData, 'transmission')),
	vehicleTypeOptions: makeOptionsFromData(get(referenceData, 'vehicle_type')),
	variantsOptions: makeModelsFromData(get(referenceData, 'variants')),
	featuresOptions: makeOptionsFromData(get(referenceData, 'feature'), ['const_key', 'id']),
	frontSeatsOptions: makeOptionsFromData(
		get(
			get(
				get(referenceData, 'featureCategories', []).find(
					(item: any) => item.const_key === 'FEATURECATEGORY_INTERIOR_EQUIPMENT',
				),
				'feature_sub_categories',
				[],
			).find((item: any) => item.const_key === 'FEATURESUBCATEGORY_FRONT_SEATS'),
			'features',
		),
	),
	rearSeatsOptions: makeOptionsFromData(
		get(
			get(
				get(referenceData, 'featureCategories', []).find(
					(item: any) => item.const_key === 'FEATURECATEGORY_INTERIOR_EQUIPMENT',
				),
				'feature_sub_categories',
				[],
			).find((item: any) => item.const_key === 'FEATURESUBCATEGORY_REAR_SEATS'),
			'features',
		),
	),
	sideMirrorsOptions: makeOptionsFromData(
		get(
			get(
				get(referenceData, 'featureCategories', []).find(
					(item: any) => item.const_key === 'FEATURECATEGORY_EXTERIOR_EQUIPMENT',
				),
				'feature_sub_categories',
				[],
			).find((item: any) => item.const_key === 'FEATURESUBCATEGORY_SIDE_MIRRORS'),
			'features',
		),
	),
	forgedBumperOptions: makeOptionsFromData(
		get(
			get(
				get(referenceData, 'featureCategories', []).find(
					(item: any) => item.const_key === 'FEATURECATEGORY_EXTERIOR_EQUIPMENT',
				),
				'feature_sub_categories',
				[],
			).find((item: any) => item.const_key === 'FEATURESUBCATEGORY_FORGED_BUMPER'),
			'features',
		),
	),
	electricWindowsOptions: makeOptionsFromData(
		get(
			get(
				get(referenceData, 'featureCategories', []).find(
					(item: any) => item.const_key === 'FEATURECATEGORY_EXTERIOR_EQUIPMENT',
				),
				'feature_sub_categories',
				[],
			).find((item: any) => item.const_key === 'FEATURESUBCATEGORY_ELECTRIC_WINDOWS'),
			'features',
		),
	),
	softCloseOptions: makeOptionsFromData(
		get(
			get(
				get(referenceData, 'featureCategories', []).find(
					(item: any) => item.const_key === 'FEATURECATEGORY_EXTERIOR_EQUIPMENT',
				),
				'feature_sub_categories',
				[],
			).find((item: any) => item.const_key === 'FEATURESUBCATEGORY_SOFT_CLOSE'),
			'features',
		),
	),
	diffLockOptions: makeOptionsFromData(
		get(
			get(
				get(referenceData, 'featureCategories', []).find(
					(item: any) => item.const_key === 'FEATURECATEGORY_VEHICLE_DRIVETRAIN',
				),
				'feature_sub_categories',
				[],
			).find((item: any) => item.const_key === 'FEATURESUBCATEGORY_DIFF_LOCK'),
			'features',
		),
	),
	priceLevels: get(referenceData, 'price_levels', []),
	dealerTags: get(referenceData, 'dealer_tag', []),
	adStatusOptions: makeOptionsFromData(get(referenceData, 'advertised_car_statuses')),
	adSourceOptions: makeOptionsFromData(get(referenceData, 'sources')),
	packageCarCountOptions: makeOptionsFromData(get(referenceData, 'number_of_advertised_cars_enum')),
	packageCreatedOptions: makeOptionsFromData(
		get(referenceData, 'offer_package_filter_created_enum'),
	),
	packageStatusOptions: makeOptionsFromData(get(referenceData, 'offer_package_status_enum')),
	languages: get(referenceData, 'language', []),
	languageOptions: get(referenceData, 'language', [])
		.filter((item: LanguageType) => FEED_SUPPORTED_LANGUAGES.includes(item.code)),
}));

export const selectSupportedReviewsCountries = createSelector(
	(state: iRootState) => state.referenceData.country,
	(countryList: Country[]) =>
		countryList && countryList.filter((country) => REVIEWS_SUPPORTED_COUNTRIES.includes(country.iso_code)),
);

